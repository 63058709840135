import React, { useEffect, useState } from "react";
import { DatePicker, Form, Input, message, Select, Switch } from "antd";
import { capitalize } from 'lodash';
import APIHelper from '../../axios/apiHelper';
import PhysicianAPI from '../../axios/physician/PhysicianApi'
import CaseTagApi from "../../axios/CaseTagApi";
import { CaseFilterMap } from "../../constants/CaseFilter";
import { ConsultTypeEnum } from "../../types/enums";

const { Option } = Select;
const {
  TELEMEDICINE_CATEGORY_ID,
  STATE_ID,
  CASE_ID,
  PATIENT_FIRST_NAME,
  PATIENT_LAST_NAME,
  TYPE,
  CREATED_DATE,
  UPDATED_DATE,
  STATUS,
  CASE_TAGS,
  BOOKMARKED,
  PHYSICIAN_ID,
} = CaseFilterMap;

const SwitchWrapper = (props) => {
  const { value, onChange } = props;
  return <Switch checked={value} onChange={onChange} />
}

const FilterForm = (props) => {
  const { telemedicines, states, hideFields, form } = props;
  const [caseStatuses, setCaseStatuses] = useState([]);
  const [caseTags, setCaseTags] = useState([]);
  const [physicians, setPhysicians] = useState([])

  const showPhysician = !hideFields?.includes(PHYSICIAN_ID);

  useEffect(() => {
    showPhysician &&
      PhysicianAPI.getPhysicians()
        .then(res => setPhysicians(res.data.physicians))
        .catch(() => message.error('Unable to fetch list of physicians'));
  }, [showPhysician])

  useEffect(() => {
    APIHelper.fetchLookUp('case-status')
      .then(res => setCaseStatuses(res.data.payload))
      .catch(() => message.error('Unable to fetch list of case status filters'));

    CaseTagApi.getCaseTags()
        .then(res => setCaseTags(res.data.caseTags))
        .catch(() => message.error('Unable to fetch list of case tags'));
  }, [])

  return (
    <Form layout="vertical" form={form}>
      {!hideFields?.includes(CASE_ID) && (
        <Form.Item name={CASE_ID} label="Case Id">
          <Input placeholder="Filter by Case Id"/>
        </Form.Item>
      )}

      {!hideFields?.includes(STATUS) && (
        <Form.Item name={STATUS} label='Case Status'>
          <Select placeholder='Filter by Case Status'>
            {caseStatuses.map(s => <Option key={s._id} value={s.name}>{s.displayName}</Option>)}
          </Select>
        </Form.Item>
      )}

      {!hideFields?.includes(CASE_TAGS) && (
        <Form.Item name={CASE_TAGS} label='Case Tags'>
          <Select mode="tags" placeholder="Case Tags">
            {caseTags.map(t => <Option key={t._id}>{t.tag}</Option>)}
          </Select>
        </Form.Item>
      )}

      {!hideFields?.includes(PATIENT_FIRST_NAME) && !hideFields?.includes(PATIENT_LAST_NAME) && (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Form.Item name={PATIENT_FIRST_NAME} label="Patient Firstname" style={{ width: '49%' }}>
            <Input placeholder="Filter by Patient Firstname"/>
          </Form.Item>
          <Form.Item name={PATIENT_LAST_NAME} label="Patient Lastname" style={{ width: '49%' }}>
            <Input placeholder="Filter by Patient Lastname"/>
          </Form.Item>
        </div>
      )}

      {!hideFields?.includes(TELEMEDICINE_CATEGORY_ID) && (
        <Form.Item name={TELEMEDICINE_CATEGORY_ID} label="Telemedicine Category">
          <Select placeholder="Filter by Telemedicine Category">
            {telemedicines?.map((t) => <Option key={t._id} value={t._id}>{t.name}</Option>)}
          </Select>
        </Form.Item>
      )}

      {!hideFields?.includes(PHYSICIAN_ID) && (
        <Form.Item name={PHYSICIAN_ID} label="Assigned Physician">
          <Select placeholder="Filter by Assigned Physician">
            {physicians?.map((p) => (
              <Option key={p._id} value={p._id}>
                {`${p.firstName} ${p.lastName}`}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}

      {!hideFields?.includes(STATE_ID) && (
        <Form.Item name={STATE_ID} label="State">
          <Select placeholder="Filter by state">
            {states?.map((s) => <Option key={s._id} value={s._id}>{s.name}</Option>)}
          </Select>
        </Form.Item>
      )}

      {!hideFields?.includes(TYPE) && (
        <Form.Item name={TYPE} label="Consultation Type">
          <Select placeholder="Filter by Type">
            {Object.values(ConsultTypeEnum).map((type) => (
              <Option key={type} value={type}>
                {capitalize(type)}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}

      {!hideFields?.includes(CREATED_DATE) && (
        <Form.Item name={CREATED_DATE} label="Created Date">
          <DatePicker
            placeholder="Filter by created Date"
            style={{ width: "100%" }}
          />
        </Form.Item>
      )}

      {!hideFields?.includes(UPDATED_DATE) && (
        <Form.Item name={UPDATED_DATE} label="Updated Date">
          <DatePicker
            placeholder="Filter by updated Date"
            style={{ width: "100%" }}
          />
        </Form.Item>
      )}

      {!hideFields?.includes(CASE_TAGS) && (
          <Form.Item name={BOOKMARKED} label="Bookmarked Cases">
            <SwitchWrapper />
          </Form.Item>
      )}

    </Form>
  )
}

export default FilterForm;
