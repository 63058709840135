import React, { useState, useEffect, useContext, useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import "./ConsultedCases.css";
import { Table, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { capitalize } from 'lodash';
import { TablePreferencesContext } from '../../context/TablePreferencesProvider';
import { dateTimeFormatter } from "../../utils/dateFormatter";
import { createCaseFilterQueryParamMap, createTableQueryParamMap, createTableSortByMap } from "../../utils/createCaseFilterQueryParamMap";
import { getDateFieldSorter, getTextFieldSorter, getSortOrder } from '../../utils/sorters';
import { CaseFilterMap, CaseQueryParamMap } from "../../constants/CaseFilter"
import { userConstants } from "../../constants";
import SearchAndFilterCases from "../SearchAndFilterCases/SearchAndFilterCases";

const hideFields = Object.freeze([CaseFilterMap.PHYSICIAN_ID, CaseFilterMap.STATUS]);

const TableDataMap = {
  TELEMEDICINE_CATEGORY_ID: CaseFilterMap.TELEMEDICINE_CATEGORY_ID,
  CASE_ID: CaseFilterMap.CASE_ID,
  CREATED_DATE: CaseFilterMap.CREATED_DATE,
  UPDATED_DATE: CaseFilterMap.UPDATED_DATE,
  STATUS: CaseFilterMap.STATUS,
  COMPLETED_AT: 'completedAt',
  PATIENT_NAME: `${CaseFilterMap.PATIENT_FIRST_NAME} ${CaseFilterMap.PATIENT_LAST_NAME}`,
}

const TableSortByMap = createTableSortByMap(TableDataMap);

const getTableColumns = (sortOrder, sortBy) => {
  const columns = [
    {
      title: "Case No",
      key: TableDataMap.CASE_ID,
    },
    {
      title: "Patient Name",
      key: TableDataMap.PATIENT_NAME,
    },
    {
      title: "Category Name",
      key: TableDataMap.TELEMEDICINE_CATEGORY_ID,
    },
    {
      title: "Created Date",
      key: TableDataMap.CREATED_DATE,
    },
    {
      title: "Updated Date",
      key: TableDataMap.UPDATED_DATE,
    },
    {
      title: "Completed Date",
      key: TableDataMap.COMPLETED_AT,
    },
  ];

  columns.forEach((c) => {
    c.dataIndex = c.key;
    if ([TableDataMap.CASE_ID].includes(c.key)) {
      return
    }
    c.sortOrder = getSortOrder(sortOrder, sortBy, c.key);
    const isDateField = [TableDataMap.CREATED_DATE, TableDataMap.UPDATED_DATE, TableDataMap.COMPLETED_AT].includes(c.key);
    c.sorter = isDateField ? getDateFieldSorter(c.key) : getTextFieldSorter(c.key);
  });

  return columns;
}

const defaultPreferences = { currentPage: 1, tablePageSize: 100, sortOrder: 'descend' }

const ConsultedCases = (props) => {
  const {
    title,
    type,
    caseListLoading,
    consultedCaseList,
    totalCount,
    actions,
    telemedicineCategoriesList,
    statesList,
  } = props;

  const history = useHistory();
  const [searchValue, setSearchValue] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [caseListFilter, setCaseListFilter] = useState({});
  const { getTablePreferences, updateTablePreferences } = useContext(TablePreferencesContext);
  const { currentPage, tablePageSize, sortOrder, sortBy } = getTablePreferences(type, defaultPreferences);

  const columns = useMemo(() => getTableColumns(sortOrder, sortBy), [sortOrder, sortBy]);

  const fetchConsultedCaseList = useCallback(() => {
    const newTablePreferences = {
      currentPage,
      tablePageSize,
      sortOrder,
      sortBy: TableSortByMap[sortBy] || sortBy,
    };
    const tableFilterMap = createTableQueryParamMap(newTablePreferences);
    const caseFilterMap = createCaseFilterQueryParamMap(caseListFilter, hideFields);
    caseFilterMap[CaseFilterMap.STATUS] = type;
    if (searchValue !== null) {
      caseFilterMap[CaseQueryParamMap.SEARCH_TERM] = searchValue;
    }
    actions.getConsultedCaseList(type, { ...tableFilterMap, ...caseFilterMap });
  }, [actions, caseListFilter, currentPage, sortOrder, sortBy, searchValue, tablePageSize, type]);

  useEffect(() => {
    fetchConsultedCaseList();
  }, [fetchConsultedCaseList]);

  const handleIntialTableCount = () => {
    updateTablePreferences(type, { currentPage: 1, tablePageSize: 100 })
  };

  const handleOk = (filterData) => {
    setCaseListFilter(filterData);
    setShowModal(false);
    handleIntialTableCount();
  };

  const searchCase = (value) => {
    setSearchValue(value);
    handleIntialTableCount();
  };

  const handleCancel = () => {
    setCaseListFilter({});
    setShowModal(false);
    handleIntialTableCount();
  };

  const handleCancelCross = () => {
    setShowModal(false);
  };

  const onChangePage = (page) => {
    updateTablePreferences(type, { currentPage: page })
  };

  const onPageSizeChange = (_, size) => {
    updateTablePreferences(type, { currentPage: 1, tablePageSize: size })
  };

  const onChangeOrder = (_pagination, _filters, sorter) => {
    updateTablePreferences(type, {
      sortOrder: sorter.order || 'ascend',
      sortBy: sorter.field,
    });
  }

  const casesForTable = consultedCaseList.map((c) => {
    return {
      key: c.caseId,
      [TableDataMap.CASE_ID]: c.caseId,
      [TableDataMap.PATIENT_NAME]: c.patientName,
      [TableDataMap.TELEMEDICINE_CATEGORY_ID]: c.category,
      [TableDataMap.CREATED_DATE]: dateTimeFormatter(c.createdDate),
      [TableDataMap.UPDATED_DATE]: c.updatedDate ? dateTimeFormatter(c.updatedDate) : '',
      [TableDataMap.COMPLETED_AT]: c.completedAt ? dateTimeFormatter(c.completedAt) : '',
      [TableDataMap.STATUS]: c.status.split(' ').map(capitalize).join(' '),
    }
  })

  return (
    <div className="consulted-case">
      <div className="waiting-room-case-list-part-heading">
        <h1>{title}</h1>
        <SearchAndFilterCases
          searchCase={searchCase}
          showModal={showModal}
          setShowModal={setShowModal}
          handleCancelCross={handleCancelCross}
          handleCancel={handleCancel}
          handleOk={handleOk}
          telemedicines={telemedicineCategoriesList}
          states={statesList}
          hideFields={hideFields}
        />
      </div>

      <div className="consulted-case-no-of-patient-list-data">
        <Spin
          size="large"
          spinning={caseListLoading}
          indicator={<LoadingOutlined spin />}
        >
          <div>
            <Table
              className="wating-room-table"
              columns={columns}
              dataSource={casesForTable}
              onChange={onChangeOrder}
              pagination={{
                total: totalCount,
                showTotal: () => `Total ${totalCount} cases`,
                current: currentPage,
                pageSize: tablePageSize,
                pageSizeOptions: ["5", "10", "20", "50", "100"],
                showSizeChanger: true,
                onChange: onChangePage,
                onShowSizeChange: onPageSizeChange,
              }}
              scroll={{ x: 900 }}
              size="large"
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    history.push(
                      `/dashboard/${userConstants.USER_PHYSICIAN}/caseview/${record.caseId}`,
                      { from: history.location.pathname.split('/').pop() }
                    );
                  },
                };
              }}
            />
          </div>
        </Spin>
      </div>
    </div>
  );
};
export default ConsultedCases;
